function getDifferenceInDays(startDate, endDate) {
  const diffInMs = Math.abs(endDate - startDate);
  return diffInMs / (1000 * 60 * 60 * 24);
}

const getEventDateString = (startDate, endDate, dateArgs) => {
  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    ...dateArgs
  }

  if (startDate === endDate) { // compares ISO date strings, NOT date objects
    return new Date(startDate).toLocaleDateString('en-GB', dateOptions)
  } else {
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startDateString = `
      ${startDate.getDate()}
      ${startDate.getMonth() !== endDate.getMonth() 
        ? ` ${startDate.toLocaleDateString('default', { month: dateOptions?.month || 'short' })}` 
        : ''
      }
    `
    const dateSeperator = getDifferenceInDays(startDate, endDate) === 1 ? '&' : '-'
    const endDateString = endDate.toLocaleDateString('en-GB', dateOptions)

    return `${startDateString} ${dateSeperator} ${endDateString}`
  }
}

export default getEventDateString