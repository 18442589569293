import React from 'react'
import styled from 'styled-components'
// import appendOrdinalIndicator from '../../helpers/appendOrdinalIndicator'

import defaultTeamLogo from '../../images/sarpl-team-logo-small.png'

const TeamsList = ({ teams }) => {
  if (teams.length === 0) {
    return <p>There are no teams.</p>
  }

  teams = sortTeams(teams)

  const halfIndex = Math.ceil(teams.length / 2)
  const teamsFirstHalf = teams.slice(0, halfIndex)
  const teamsSecondHalf = teams.slice(halfIndex, teams.length)

  return (
    <StyledTeamsList id="teams-list">
      <div>
        {generateTeamListItemElements(teamsFirstHalf)}
      </div>
      {teamsSecondHalf.length !== 0 && (
        <div>
          {generateTeamListItemElements(teamsSecondHalf)}
        </div>
      )}
    </StyledTeamsList>
  )
}

const sortTeams = (teams) => {
 return teams.sort((a, b) => { // sort by alphabetically by name
    const nameA = a.team.name.toUpperCase()
    const nameB = b.team.name.toUpperCase()

    if (nameA < nameB) {
      return -1
    } else if (nameB < nameA) {
      return 1
    }

    return 0
  }).sort((a, b) => { // sort by position
    if (a.position === null) {
      return 1
    } else if (b.position === null) {
      return -1
    }

    return a.position - b.position
  })
}

const generateTeamListItemElements = (teams) => {
  return teams.map(({ team, position }) => (
    <div key={team.name}>
      {team.logo 
        ? <img src={team.logo?.asset.url} alt="" height="40px" />
        : <img src={defaultTeamLogo} alt="" height="40px" />
      }
      <div>
        <span>{team.name}</span>
        {position && <span className='position' data-position={position}>{position}</span>}
      </div>
    </div>
  ))
}

//  && appendOrdinalIndicator(position)

const StyledTeamsList = styled.div`
  --border-style: solid 1px rgba(255, 255, 255, 0.2);

  display: flex;
  flex-flow: row wrap;
  column-gap: 1rem;
  padding: 1rem;
  border: var(--border-style);
  border-radius: 20px;

  /* list container of teams */
  & > * {
    flex: 1 1 220px;

    &:nth-child(2) {
      /* margin-left: 1.5rem; */
      padding-left: 1rem;
      border-left: var(--border-style);
    }
  }

  /* team list item */
  & > * > * {
    display: flex;
    gap: 1rem;
    align-items: center;
    height: 2.5em;
    
    img {
      flex: 0 0 auto;
      width: 40px;
      height: 100%;
      object-fit: contain;
    }
    
    & > div {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      /* padding: 0.8em 0; */
      border-bottom: var(--border-style);
    }

    span.position {
      background-color: var(--primary-color);
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      border-radius: 50%;
      font-weight: bold;
      text-shadow: 0px 0px 8px rgba(0,0,0,0.5);

      &[data-position='1'] {
        background-color: #D4AF37;
      }

      &[data-position='2'] {
        background-color: #AAA9AD;
      }

      &[data-position='3'] {
        background-color: #CD7F32;
      }
    }
  }

  @media (max-width: 962px) {
    /* list container of teams */
  & > * {
    &:nth-child(2) {
      padding-left: 0;
      border-left: none;
    }
  }
  }
`

export default TeamsList