import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Seo from '../../components/SEO'
import getEventDateString from '../../helpers/getEventDateString'
import SectionDivider from '../../components/SectionDivider'
import RulebookBtn from '../../components/RulebookBtn'
import TeamsList from '../../components/event-page/TeamsList'
import RegistrationButton from '../../components/event-page/RegistrationButton'
import Videos from '../../components/event-page/EventVideos'
import Gallery from '../../components/event-page/EventGallery'

const EventPage = ({ data }) => {
  const {
    title,
    type,
    startDate,
    endDate,
    registration,
    divisions,
    videoEmbeds: videos,
    galleries,
    location
  } = data.sanityEvent

  const rulebook = data.sanitySiteSettings.rulebooks.find(rulebook => rulebook.type.toLowerCase() === type.toLowerCase())
  const eventIsOver = new Date() >= new Date(endDate)

  const galleriesElements = galleries.map(({title, images}) => (
    <div key={title} style={{ marginBottom: '1rem'}}>
      <h4>{title}</h4>
      <Gallery images={images} />
    </div>
  ))

  return (
    <>
      <Seo title={title} image={location?.featuredImage.asset.url} />
      <StyledEventPage>
        <div className="site-container">
          <section id='event'>
            <aside>
              {location 
                ? <GatsbyImage 
                    image={getImage(location?.featuredImage.asset)} 
                    alt={`${location.name} featured`} 
                    loading='eager' 
                  />
                : <StaticImage 
                    src='../../images/event-tbc.jpg' 
                    alt='Location to be confirmed' 
                    loading='eager' 
                    quality={100} 
                  />
              }
            </aside>

            <div>
              <header>
                <small>{type}</small>
                <h1>{title}</h1>
              </header>

              {/* DATE */}
              <div className="event-content">
                <div>
                  <h3>When:</h3>
                  <p>{getEventDateString(startDate, endDate, { month: 'long' })}</p>
                </div>

                {/* LOCATION */}
                <div>
                  <h3>Where:</h3>
                  <p>{location?.name || 'TBC'}</p>
                </div>

                {/* DIVISIONS & TEAMS */}
                {divisions.length !== 0 && (
                  <div className='col-span-full'>
                    <h3>Teams:</h3>
                    {divisions.map(({ title, teams }) => (
                      <div key={title}>
                        {title !== 'Open' && <h4>{title}</h4>}
                        <TeamsList teams={teams} />
                      </div>
                    ))}
                  </div>
                )}

                {/* REGISTRATION BUTTON */}
                {(registration && !eventIsOver) &&
                  <div className='col-span-full'>
                    <RegistrationButton 
                      link={registration.registrationLink} 
                      closeDate={registration.registrationEnd}
                    />
                  </div>
                }

                {/* RULEBOOK BUTTON */}
                {rulebook && (
                  <div className='col-span-full'>
                    <RulebookBtn title={rulebook.title} assetURL={rulebook.asset.url} />
                  </div>
                )}

                {/* <h3>Event Sponsors:</h3> */}

                {/* VIDEOS */}
                {videos.length !== 0 && (
                  <div className='col-span-full'>
                    <h3>Videos</h3>
                    <Videos videoURLs={videos} />
                  </div>
                )}

                {/* GALLERIES */}
                {galleries.length !== 0 && (
                  <div className="col-span-full">
                    <h3>Galleries</h3>
                    {galleriesElements}
                  </div>
                )}
              </div>
            </div>
          </section>

          <SectionDivider />

          {location && <section id='field'>
            <aside>
              <GatsbyImage id='logo' image={getImage(location?.logo.asset)} alt={location.name} />
            </aside>
            
            <div>
              <h2>About the Field:</h2>

              <h3>{location.name}</h3>
              <p>{location.description}</p>
              <a className='btn' href={location.directionsLink} target='_blank' rel='noreferrer'>Get Directions</a>

              <iframe className='map-embed' title='Google map' src={location.gmapsIframeSrc} width="600" height="450" style={{border: 0}} allowFullScreen loading="lazy" />
            </div>
          </section>}
        </div>
      </StyledEventPage>
    </>
  )
}

export const query = graphql`
  query EventPageQuery($id: String) {
    sanityEvent(id: { eq: $id }) {
      title
      type
      startDate
      endDate
      registration {
        registrationLink
        registrationEnd
      }
      divisions {
        title
        teams {
          position
          team {
            name
            logo {
              asset { url }
            }
          }
        }
      }
      videoEmbeds
      galleries {
        title
        images {
          asset { 
            filename
            url
            gatsbyImageData
          }
        }
      }
      location {
        name
        description
        directionsLink
        gmapsIframeSrc
        featuredImage {
          asset { 
            gatsbyImageData 
            url
          }
        }
        logo {
          asset { gatsbyImageData }
        }
      }
    }
    sanitySiteSettings {
      rulebooks {
        title
        type
        asset { url }
      }
    }
  }
`

const StyledEventPage = styled.main`
  /* padding: 2rem 2rem 0rem 2rem; */
  padding: 2rem 0 0 0;

  section {
    display: grid;
    grid-template-columns: 22rem 1fr;
    grid-gap: 2rem;
    margin-bottom: 4rem;

    & > div {
      border-left: solid 1px rgba(255, 255, 255, 0.2);
      padding-left: 1.5rem;
    }
  }

  h1, h2, h3 {
    text-transform: uppercase;
  }

  h1 {
    margin-bottom: 1rem;
    font-size: 3.5rem;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  h3 {
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
  }

  h4 {
    margin-bottom: 0.4rem;
  }

  p {
    /* margin-bottom: 1rem; */
    font-size: 1.1rem;
    line-height: 1.25;
  }

  ul {
    list-style: inside;
  }

  small {
    font-size: 1rem;
  }

  aside img {
    border-radius: 20px;
  }
  
  #logo {
    /* filter: drop-shadow(5px 5px 5px #000000); */
    background-color: #f4f4f4;
    padding: 1rem;
  }

  a.btn {
    display: inline-block;
    background-color: var(--primary-color);
    margin: 1rem 0;
    padding: 0.6em 1.2em;
    border-radius: 50px;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  .event-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  .videos-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-gap: 1.5rem;
  }

  .map-embed {
    display: block;
    margin-top: 1.25rem;
    width: 100%;
    height: 300px;
  }

  section#field {
    h2 {
      margin-bottom: 0.4rem;
      font-size: 1.5rem;
    }

    h3 {
      margin-bottom: 1rem;
      font-size: 3rem;
    }
  }

  #teams-list {
    margin-bottom: 1rem;
  }

  #rulebook-btn {
    /* margin-top: 1rem; */
  }

  @media (max-width: 768px) {
    section {
      grid-template-columns: 1fr;

      & > div {
        border-left: none;
        padding-left: 0;
      }

      &#event {
        aside {
          img {
            height: 300px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 2rem 0 0 0;

    section {
      grid-gap: 1rem;

      &#event {
        aside {
          img {
            height: 200px;
            width: 100%;
          }
        }
      }
    }
  }
`

export default EventPage