import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import sharedEventSwiperProps from './sharedEventSwiperProps'
import * as styles from './Swiper.module.scss'

const EventGallery = ({ images }) => {
  const goToImage = (url) => {
    // window.location.assign(url)
    window.open(url)
  }

  return (
    <Swiper
      {...sharedEventSwiperProps}
      navigation
      modules={[Navigation]}
      className={styles.swiper}
    >
      {images.map(image => (
        <SwiperSlide key={image.asset.url} onClick={() => goToImage(image.asset.url)}>
          <GatsbyImage image={getImage(image.asset.gatsbyImageData)} alt={image.asset.filename} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default EventGallery