import React from 'react'
import styled from "styled-components";

const SectionDivider = () => (
  <StyledSectionDivider>
    <div></div>
    <div></div>
    <div></div>
  </StyledSectionDivider>
)

const StyledSectionDivider = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin: 4rem auto;

  & > * {
    background-color: var(--primary-color);
    height: 10px;
    width: 10px;
    border-radius: 50%;

    &:nth-child(2) {
      width: 4rem;
      border-radius: 10px;
    }
  }
`

// const SectionDivider = styled.div`
//   background-color: var(--primary-color);
//   height: 4px;
//   width: 200px;
//   margin: 4rem auto;
//   border-radius: 2px;
// `

export default SectionDivider