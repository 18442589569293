import React from 'react'
import styled from 'styled-components'

const localeTimeStringOptions = {
  hour: '2-digit',
  minute: '2-digit'
}

const localeDateStringOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
}

const RegistrationButton = ({ link, closeDate }) => {
  const registrationEndDate = closeDate ? new Date(closeDate) : null

  if (registrationEndDate) {
    const registrationClosed = registrationEndDate < new Date()

    if (registrationClosed) {
      return (
        <StyledClosedRegistration>
          Registration closed
        </StyledClosedRegistration>
      )
    }
  }

  const endTimeString = registrationEndDate?.toLocaleTimeString(undefined, localeTimeStringOptions)
  const endDateString = registrationEndDate?.toLocaleDateString(undefined, localeDateStringOptions)

  return (
    <StyledRegistrationButton>
      <a className='btn' href={link} target="_blank" rel="noreferrer">Register For Event</a>
      {(endTimeString && endDateString) && <small>Registration closes on {endDateString} at {endTimeString}</small>}
    </StyledRegistrationButton>
  )
}

const StyledRegistrationButton = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 1rem;

  a {
    flex: 0 0 auto;
  }

  small {
    flex: 0 0 auto;
    color: rgba(255, 255, 255, 0.8);
    font-size: smaller;
  }
`

const StyledClosedRegistration = styled.p`
  display: inline-block;
  background-color: #444;
  margin: 1rem 0;
  padding: 0.5em 1.5em;
  border-radius: 50px;
  cursor: not-allowed;
`

export default RegistrationButton