const sharedEventSwiperProps = {
  slidesPerView: 3.1,
  spaceBetween: 16,
  breakpoints: {
    320: {
      slidesPerView: 1.1,
    },
    480: {
      slidesPerView: 2.1,
    },
    640: {
      slidesPerView: 3.1,
    }
  }
}

export default sharedEventSwiperProps