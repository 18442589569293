import React from 'react'
import ReactPlayer from 'react-player'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import sharedEventSwiperProps from './sharedEventSwiperProps'
import * as styles from './Swiper.module.scss'

const EventVideos = ({ videoURLs }) => {
  return (
    <Swiper
      {...sharedEventSwiperProps}
      navigation
      modules={[Navigation]}
      className={styles.swiper}
    >
      {videoURLs.map((url) => (
        <SwiperSlide key={url} style={{aspectRatio: '16 / 9'}}>
          <ReactPlayer url={url} controls width='100%' height='100%' />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default EventVideos