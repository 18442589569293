import React from 'react'
import styled from 'styled-components'
import { FaFilePdf } from 'react-icons/fa'

const RulebookBtn = ({ title, assetURL}) => (
  <StyledRulebookBtn id="rulebook-btn" href={assetURL}><FaFilePdf /> {title}</StyledRulebookBtn>
)

const StyledRulebookBtn = styled.a`
  display: block;
  background-color: #ED2224;
  margin: 0 1rem 1rem 0;
  padding: 0.5em 1em;
  width: fit-content;
  border-radius: 10px;
  text-decoration: none;
`

export default RulebookBtn